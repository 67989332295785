import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Terms = () => {
  return (
    <Layout>
      <SEO description="Request veendor services through BuilderCover. We put builders and project owners in touch with the people needed to make construction projects successful."
        title="Request Construction Services" 
        keywords={[`construction services`, `building services`, `engineering`, `construction insurance`, `construction finance`]} />
        <section className="hero is-small is-primary">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-1 is-hidden-touch">
                </div>
                <div className="column is-8">
                  <h2 className="subtitle is-size-4 is-uppercase has-text-info">
                    Terms and Conditions
                  </h2>
                  <h1 className="title has-text-white is-size-1-desktop is-size-2-touch has-text-weight-bold">
                    Your data is safe with BuilderCover.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section container">
            <div class="cognito">
                Terms here.
                
            </div>
        </section>
    </Layout>
  )
}

export default Terms